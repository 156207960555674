export default {
  namespaced: true,

  state: {
    selectedWorker: null,
    event: null,
    upSheet: false,
  },

  getters: {
    selectedWorker: (state) => state.selectedWorker,
    event: (state) => state.event,
    upSheet: (state) => state.upSheet,
  },

  actions: {
    setSelectedWorker(context, value) {
      context.commit('setSelectedWorker', value);
    },
    setEvent(context, value) {
      context.commit('setEvent', value);
    },
    changeUpSheet(context, value) {
      context.commit('changeUpSheet', value);
    },
  },

  mutations: {
    setSelectedWorker(state, user) {
      state.selectedWorker = user;
    },
    setEvent(state, event) {
      state.event = event;
    },
    changeUpSheet(state) {
      state.upSheet = !state.upSheet;
    },
  },
};
