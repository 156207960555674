<template>
  <f7-page
    name="home"
    class="backgroundPage"
    @page:beforein="obtenerLocalizaciones"
  >
    <navbar/>
    <Gis
      :token="JSON.parse(loggedUser).token"
    />
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';

import navbar from '../components/NavBar.vue';
import Gis from '../components/Gis.vue';
import EventBus from '../js/event-bus';
import Api from '../services/Api';

export default {
  components: {
    navbar,
    Gis,
  },
  data() {
    return {
      navbar,
    };
  },
  computed: {
    ...mapState('AccessControl', ['currentLocationCode']),
    ...mapState('authentication', ['loggedUser']),
    locationSelectedTitle() {
      if (!this.locationSelectedCode) {
        return 'Buscar';
      }
      return this.locations.filter(
        (item) => item.code === this.locationSelectedCode,
      )[0].name;
    },
  },
  methods: {
    async obtenerLocalizaciones() {
      try {
        const xhr = await Api.getLocations();
        const locations = xhr.response;
        EventBus.$emit('locations', locations);
      } catch (e) {
        console.log(e);
      }
    },
  },

};
</script>

<style scoped>
.contenedor {
  background: #f4f5f7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}
.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.change-location-btn {
  padding: 10px 5px 6px;
}

.item-title-block {
  padding: 15px 20px;
}

.content-location {
  display: block;
  border: 1px solid #e5eef7;
}

.header-space {
  position: relative;
  padding: 20px 35px;
}
.number-icon {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: #556080;
  padding: 0 10px 0 10px;
}
.list .item-title {
  padding: 10px 0 5px;
  margin-right: 10px;
  font-size: 18px;
  letter-spacing: -0.04em;
}
.item-title-row {
  align-items: center;
}
.list .item-subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #9b9b9b;
  padding: 2px 0px 0px;
}
.hour {
  font-weight: 500;
  font-size: 26px;
  letter-spacing: -0.04em;
  line-height: 1.2;
  color: #556080;
  margin: 2px;
}

.date {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #c4c4c4;
}
.icon-status {
  padding: 22px 5px 0;
}
.item-link.item-content {
  padding-left: 0;
}
.smart-border {
  left: 1.67%;
  right: 1.67%;
  top: 14.56%;
  bottom: 81.22%;

  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-left: 9px;
  width: 97%;
}
div.item-inner.infoUser::after{
  display: none;
}
.backgroundPage{
  background-color: white;
}
.virtual-list ul{
  height: auto !important;
}
.block-title-medium {
      color: #9B9B9B !important;
}
</style>
