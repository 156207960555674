import {
  f7,
} from 'framework7-vue';
import Request from './Request';
import EventBus from '../js/event-bus';

let username = '';
let token = '';
let headerAuth = {};
const baseUrl = 'https://api.globalviti.hispatecanalytics.com';

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  username = data.username;
  token = data.token;
  headerAuth = `Bearer ${token}`;
});

export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!username) return;
        username = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  // Login
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        username: credentials.username,
        password: credentials.password,
      }),
    };

    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },

  async handleLoginResult(result) {
    EventBus.$emit('newLoggedUser', result.response);
  },

  // Reset password
  recoveryPassword(email, urlClient) {
    const url = `${baseUrl}/user/password_reset/`;
    const payload = {
      data: JSON.stringify({
        url: urlClient,
        email,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json');
  },

  // Dictionary
  getDictionary(endpoint, namespace, language) { // TODO: Esto es solo un concepto, no es funcional!
    const url = `${baseUrl}${endpoint}`;
    const auth = {
      username,
      token,
    };
    const data = {
      namespace,
      language,
    };
    const payload = {
      auth: JSON.stringify(auth),
      data: JSON.stringify(data),
    };
    return this.request(url, payload, 'GET', false, 'none');
  },

  // Reset password
  access(type, code) {
    const url = `${baseUrl}/employees/access/`;
    const payload = {
      data: JSON.stringify({
        type,
        code,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json', headerAuth);
  },

  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    // if (!('auth' in payload)) {
    //   payload.auth = JSON.stringify({ username, token });
    // }

    const response = await Request.async(url, payload, verb, processData, contentType);

    // if (typeof response === 'object' && 'result' in response && (response.result === 'fail' || !response.result)) {
    //   const error = this.getException(response.errorcode);
    //   error.callback();
    //   throw new Error(error.message);
    // }

    return response;
  },

  getException(errorcode) {
    return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
  },
  // Get access
  getAccess() {
    const url = `${baseUrl}/employees/access/`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },

  // List employess
  getEmployees(limit = 0, page = 0) {
    const url = `${baseUrl}/employees?limit=${limit}&offset=${page}`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
  getLocations() {
    const url = `${baseUrl}/globalviti/locations`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth);
  },
};
