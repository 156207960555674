<template>
  <f7-sheet
    class="demo-sheet"
  >
    <f7-page-content style="height:auto">
      <f7-row>
        <f7-col width="25">
          <div class="item-media">
            <div class="event-type-icon">
              <img
                width="58"
                height="58"
                class="ic-user"
                style="padding:25%"
              >
            </div>
          </div>
        </f7-col>
        <f7-col
          width="45"
          style="margin-top:5%;color:black;"
        >
          <strong v-if="selectedWorker!=null">
            {{ selectedWorker.name }} {{ selectedWorker.surname }}
          </strong>
          <div style="color:green" />
        </f7-col>
        <f7-col width="30">
          <div class="item-media">
            <div class="event-type-icon">
              <img
                v-if="event!=null && event.type=='IN'"
                class="ic-ok"
                width="100%"
                height="100%"
              >
              <img
                v-else
                class="ic-out"
                width="100%"
                height="100%"
              >
            </div>
          </div>
        </f7-col>
      </f7-row>
    </f7-page-content>
  </f7-sheet>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SheetNFC',
  data() {
    return {
    };
  },

  computed: {
    ...mapState('worker', ['selectedWorker', 'event']),
  },
};
</script>

<style lang="scss" scoped>
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}

.ic-ok {
  content:url('../static/img/ic_ok.svg');
}
.ic-out {
  content:url('../static/img/ic_out.svg');
}
.ic-user {
  content:url('../static/img/ic_user.svg');
}
</style>
