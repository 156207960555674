import Framework7 from 'framework7';

export default {
  request(url, payload, verb, processData = true, contentType = 'application/x-www-form-urlencoded', headerAuth,
    callback, timeout = 20000) {
    Framework7.request.setup({
      headers: {
        Authorization: headerAuth,
      },
    });
    Framework7.request({
      url,
      method: verb,
      cache: false,
      data: payload.data,
      processData,
      contentType,
      timeout,
      complete: callback,
    });
  },

  async(url, payload, verb, processData, contentType, headerAuth = null) {
    const self = this;
    return new Promise(((resolve, reject) => {
      self.request(url, payload, verb, processData, contentType, headerAuth,
        (xhr, status) => {
          if (status >= 300) { // TODO: Tratar adecuademente las respuestas (1xx,2xx,3xx,4xx,5xx)
            reject(status);
            return;
          }
          try {
            resolve(xhr);
          } catch (e) {
            reject(e);
          }
        });
    }));
  },

  async blobRequest(url, callback) {
    const logoRes = await fetch(url);
    const blob = await logoRes.blob();
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(blob);
  },
};
