<template>
  <f7-page name="nfcManually">
    <navBarPresence
      :new-icon-enable="false"
      :text="$t('Nfc_navbarpresence_manual')"
    />
    <f7-page-content>
      <manual-list />
      <!-- Buttons -->
      
    </f7-page-content>
  </f7-page>
</template>

<script>

import navBarPresence from '../../components/presence/NavBarPresence.vue';

import ManualList from '../../components/presence/ManualList.vue';

export default {
  name: 'NfcManually',
  components: {
    navBarPresence,
    ManualList,
  },

  data() {
    return {
      items: [],
      btnDisable: true,
      vlData: {
        items: [],
      },
      fullName: { type: String, default: '' },
      
      employees: { type: Array, default: () => [] },
    };
  },
  
  methods: {
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.worker = item;
    },
    
    
  },
};
</script>
<style lang="scss" scoped>

</style>
