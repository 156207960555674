import HomePage from '../pages/home.vue';
import Login from '../pages/accounts/loginPage.vue';
import RecoveryPasswordPage from '../pages/accounts/recoveryPasswordPage.vue';
import ChangePasswordPage from '../pages/accounts/changePasswordPage.vue';
import Register from '../pages/accounts/register.vue';
import Logout from '../pages/accounts/logout.vue';
import NfcPage from '../pages/presence/nfcPage.vue';
import NfcManually from '../pages/presence/nfcManually.vue';
import NotFoundPage from '../pages/404.vue';
import ExportWorker from '../pages/ExportWorkerList.vue';

const routes = [
  {
    path: '/',
    component: HomePage,
  },
  // Accounts
  {
    path: '/accounts/loginPage/',
    component: Login,
  },
  {
    path: '/recoveryPasswordPage/',
    component: RecoveryPasswordPage,
  },
  {
    path: '/changePasswordPage/',
    component: ChangePasswordPage,
  },
  {
    path: '/register/',
    component: Register,
  },
  {
    path: '/accounts/logout/',
    component: Logout,
  },
  // Presence
  {
    path: '/nfc/',
    component: NfcPage,
  },
  {
    path: '/nfcManually/',
    component: NfcManually,
  },
  // Others
  {
    path: '/home/',
    component: HomePage,
  },
  {
    path: '/ExportWorkerList/',
    component: ExportWorker,
  },
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
