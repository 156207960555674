<template>
  <iframe
    v-if="locations!=null"
    id="mapaGis"
    title="GIS MAP"
    :src="`./static/gis/index.html?token=${token}&locations=${locations}`"
  />
  <f7-block v-else class="blockPosition">
    <div class="content-block text-align-center">
      <f7-block-title medium class="colorText">
        Seleccione parcela
      </f7-block-title>
    </div>
  </f7-block>
</template>

<script>

import EventBus from '../js/event-bus';

export default {
  name: 'Gis',
  props: { token: { type: String, default: '' } },
  data() {
    return {
      locations: null,
    };
  },
  beforeMount() {
    EventBus.$on('locationSelected', this.saveLocations);
  },
  methods: {
    saveLocations(locationsJson) {
      this.locations = locationsJson;
    },
  },
};
</script>

<style scoped>
#mapaGis{
  position: fixed !important;
  width:100%;
  height:100%;
}
.colorText{
  color: #3F1815;
}
.blockPosition{
  top: 40%;
}
</style>
