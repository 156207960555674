import AccessControl from '../../services/AccessControl';
import { SECONDS_BETWEEN_SCANS } from '../../js/constants';

export default {
  namespaced: true,

  state: {
    currentLocationCode: '',
    canScan: false,
    persona: null,
    personNotExists: false,
    lastScan: { type: '', identification: '', timestamp: 0 },
  },

  actions: {
    selectLocation(context, value) {
      context.commit('setCurrentLocation', value);
    },

    initScanner(context) {
      context.commit('setCanScan', true);
    },

    stopScanner(context) {
      context.commit('setCanScan', false);
    },

    closeModal(context) {
      context.commit('setPersona', null);
      context.commit('setPersonNotExists', false);
    },

    scan(context, payload) {
      const locationId = context.state.currentLocation.id;
      const scanType = payload.type;
      const scanId = payload.identification;
      const { lastScan } = context.state;

      return new Promise(((resolve, reject) => {
        const now = Date.now();
        const interval = now - lastScan.timestamp;
        const intervalTooLow = interval < SECONDS_BETWEEN_SCANS * 1000;
        if (scanType === lastScan.type && scanId === lastScan.identification && intervalTooLow) {
          resolve();
          return;
        }

        context.commit('updateLastScan', { type: scanType, identification: scanId });

        if (!context.state.canScan) {
          reject(Error('Scan is not allowed at this moment'));
          return;
        }

        AccessControl.findEmployeeByIdentification(scanType, scanId)
          .then((tempResult) => {
            tempResult.motivoIncidencia = 'Enviando al servidor';
            context.commit('setPersona', tempResult);
            AccessControl.scanEmployee(locationId, scanType, scanId, tempResult)
              .then((result) => {
                context.commit('setPersona', result);
                resolve(result);
              });
          }).catch(() => {
            context.commit('setPersonNotExists', true);
            context.commit('setPersona', null);
            reject();
          });
      }));
    },

    destroy(context) {
      return new Promise(((resolve) => {
        context.commit('setCurrentLocation', null);
        resolve();
      }));
    },
  },

  mutations: {
    setCurrentLocation(state, value) {
      state.currentLocationCode = value;
    },

    setCanScan(state, value) {
      state.canScan = value;
    },

    setPersona(state, value) {
      state.persona = value;
    },

    setPersonNotExists(state, value) {
      state.personNotExists = value;
    },

    updateLastScan(state, value) {
      state.lastScan = {
        type: value.type,
        identification: value.identification,
        timestamp: Date.now(),
      };
    },
  },
};
