<template>
  <f7-page
    name="nfcPage"
    @page:beforein="beforein"
    @page:beforeout="beforeout"
  >
    <navBarPresence
      :new-icon-enable="true"
      :text="$t('Nfc_navbarpresence_incidence')"
    />
    <f7-page-content>
      <f7-block class="text-align-center">
        <f7-icon icon="icon-scan" />
      </f7-block>
      <f7-block name="info">
        <div class="content-block text-align-center">
          <f7-block-title medium>
            {{ $t("Nfc_info_txtTitle") }}
          </f7-block-title>
          <p>{{ $t("Nfc_info_txtSubTitle") }}</p>
        </div>
      </f7-block>
      <f7-button
        fill
        sheet-open=".demo-sheet"
        @click="virtualNFC"
      >
        NFC
      </f7-button>
    </f7-page-content>
    <SheetNFC />
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navBarPresence from '../../components/presence/NavBarPresence.vue';
import EventBus from '../../js/event-bus';
import SheetNFC from '../../components/SheetNFC.vue';
import Api from '../../services/Api';

export default {
  name: 'NfcPage',

  components: {
    navBarPresence,
    SheetNFC,
  },

  data() {
    return {
      manualAccessControl: false,
      eventAction: (newId) => this.manualAccessAction(newId),
    };
  },
  computed: {
    scanDetailsOpened() {
      return this.persona !== null || this.personNotExists === true;
    },
    ...mapState('worker', ['selectedWorker', 'event', 'upSheet']),
  },
  methods: {
    beforein() {
      this.updateStateSheet();
      this.subscribeNFC();
    },
    beforeout() {
      EventBus.$off('nfc-scan', this.scanNFC);
    },
    subscribeNFC() {
      EventBus.$on('nfc-scan', this.scanNFC);
    },
    virtualNFC() {
      EventBus.$emit('nfc-scan', '04DFA3EA265E80');
    },
    scanNFC(tag) {
      this.access('NFC', tag);
    },

    modalInfoClosed() {
      this.closeModal();
    },

    async access(type, code) {
      const xhr = await Api.access(type, code);
      const json = JSON.parse(xhr.response);
      this.setSelectedWorker(json);
      this.setEvent(json.event);
    },

    updateStateSheet() {
      if (this.upSheet === true) {
        this.$f7.sheet.open('.demo-sheet');
        this.changeUpSheet();
      }
    },
    ...mapActions('worker', ['changeUpSheet', 'setSelectedWorker', 'setEvent']),
  },
};
</script>
<style lang="scss" scoped>
.page-content {
  color: #9b9b9b !important;

  .block-title-medium {
    color: #9b9b9b !important;
  }
}
div.demo-sheet.sheet-modal.sheet-modal-bottom.modal-in{
  height: 15%;
}
</style>
