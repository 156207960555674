<template>
  <div class="container">
    <f7-searchbar
      search-container=".search-list"
      search-item="li"
      search-in=".item-title"
      placeholder="Search"
    />

    <f7-list class="searchbar-not-found">
      <f7-list-item title="Nothing found" />
    </f7-list>

    <f7-list
      class="search-list searchbar-found"
      virtual-list
      :virtual-list-params="vlParams"
    >
      <ul>
        <f7-list-item
          v-for="employee in vlData.items"
          :key="employee.code"
          radio
          :title="employee.fullname"
          radio-icon="start"
          name="demo-radio-start"
          @click="changeStatebtnDisable(employee.object)"
        />
      </ul>
    </f7-list>

    <f7-block>
        <f7-button
          name="btnContinue"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="saveWorker()"
        >
          {{ $t("Nfc_navbarpresence_btnContinue") }}
        </f7-button>
      </f7-block>
  </div>
</template>

<style lang="scss" scoped>
  .list .item-subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #999;
    mix-blend-mode: normal;
    opacity: 0.8;
  }
.searchbar-found{
  height: 338px !important;
  overflow: scroll;
  background: white;
}
</style>

<script>
import { mapActions } from 'vuex';
import Api from '../../services/Api';

export default {
  props: {
    employees: { type: Array, default: () => [] },
    callbackSelected: { type: Function, default: () => {} },
  },
  data() {
    return {
      vlData: {
        items: [],
      },
      vlParams: {
        items: this.employees,
        searchAll: this.searchAll,
        renderExternal: this.renderExternal,
        // height: this.$theme.ios ? 63 : (this.$theme.md ? 73 : 46)
      },
      worker: null,
      btnDisable: true,
    };
  },
  async beforeMount() {
    const xhr = await Api.getEmployees(15, 0);
    const xhrFormat = JSON.parse(xhr.response).data;
    for (let i = 0; i < xhrFormat.length; i += 1) {
      this.employees.push({
        fullname: `${xhrFormat[i].name} ${xhrFormat[i].surname}`,
        code: xhrFormat[i].code,
        object: xhrFormat[i],
      });
    }
    this.vlData.items = this.employees;
  },
  methods: {
    searchAll(query, items) {
      const result = [];
      const lowerQuery = query.toLowerCase();
      items.forEach((item, i) => {
        if (item.fullname.toLowerCase().includes(lowerQuery)
        ) {
          result.push(i);
        }
      });
      return result;
    },
    renderExternal(vl, vlData) {
      this.vlData = vlData;
    },
    saveWorker() {
      this.setSelectedWorker(this.worker);
      if (this.worker.auth.length > 0) {
        this.access(this.worker.auth[0].type, this.worker.auth[0].code);
      }
      this.changeUpSheet();
      this.$f7router.back();
    },
    changeStatebtnDisable(item) {
      this.btnDisable = false;
      this.worker = item;
    },
    async access(type, code) {
      const xhr = await Api.access(type, code);
      const json = JSON.parse(xhr.response);
      this.setEvent(json.event);
    },
    ...mapActions('worker', ['setSelectedWorker', 'setEvent', 'changeUpSheet']),
  },
};
</script>
<style scoped>
.block{
  position: absolute;
    bottom: 170px;
    width: 100%;
}
.container {
  background: #F4F5F7;
  padding-top: 10px;
}
.searchbar-input-wrap{
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 4px;
}
.searchbar{
  height: 40px;
}
.searchbar-not-found{
  text-align: center;
}
</style>