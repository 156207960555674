<template>
  <div>
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
      </div>

      <f7-list
        form
        no-hairlines-between
      >
        <!-- Username -->
        <f7-list-input
          name="inpUsername"
          outline
          item-label
          type="text"
          :placeholder="$t('Login_inpUsername_placeholder')"
          clear-button
          max="255"
          required
          validate
          @input="username = $event.target.value"
        />
        <!-- Password -->
        <f7-list-input
          name="inpPassword"
          outline
          item-label
          type="password"
          :placeholder="$t('Login_inpPassword_placeholder')"
          clear-button
          max="255"
          required
          autocomplete="current-password"
          validate
          @input="password = $event.target.value"
        />
      </f7-list>

      <!-- Buttons -->
      <f7-list>
        <!-- // TODO: No está gestionada la funcionalidad.  -->
        <f7-list-item
          v-if="hasRememberMe"
          checkbox
          value="chkRememberMe"
          name="chkRememberMe"
          :title="$t('Login_chkRememberMe')"
          :checked="wantToRemember"
          @change="setWantToRemember"
        />

        <f7-button
          name="btnSignIn"
          class="botonPrimary"
          col
          button
          button-large
          button-raised
          fill
          :disabled="btnDisable"
          @click="signIn"
        >
          {{ $t("Login_btnSignIn") }}
        </f7-button>
      </f7-list>
    </f7-block>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../../js/constants';
import Api from '../../services/Api';

export default {
  name: 'Login',
  props: {
    hasRestorePass: { type: Boolean, default: false },
    hasSignUp: { type: Boolean, default: false },
    hasRememberMe: { type: Boolean, default: false },
    hasNeedHelp: { type: Boolean, default: false },
  },
  data() {
    return {
      username: '',
      password: '',
      APPLICATION_NAME,
      APP_LOGO,
    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.wantToRemember && !this.password;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['isLogged', 'wantToRemember']),
  },

  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        await Api.login({
          username: this.username,
          password: this.password,
        });
      } catch (reason) {
        this.$f7.dialog.alert(reason);
      }
      this.$f7.preloader.hide();
    },
    signUp() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/recoveryPasswordPage/');
    },
    quit() {
      const self = this;
      const router = self.$f7router;
      router.back();
    },
    ...mapActions('authentication', ['login']),
    ...mapMutations('authentication', ['setWantToRemember', 'wantToRecoveryPass', 'changePassword']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/accounts/login.scss";
</style>
