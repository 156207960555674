<template>
  <f7-navbar
    :sliding="false"
  >
    <f7-nav-left>
      <f7-link
        icon-ios="f7:menu"
        icon-aurora="f7:menu"
        icon-md="material:menu"
        panel-open="left"
      />
    </f7-nav-left>
    <f7-nav-right>
      <location-picker/>
    </f7-nav-right>
  </f7-navbar>
</template>

<style lang="scss" scoped>
</style>

<script>

import { mapState } from 'vuex';
import LocationPicker from './LocationPicker.vue';

export default {
  components: {
    LocationPicker,
  },
  props: { elements: { type: Array, default: () => [] }, list: { type: Array, default: () => [] } },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('authentication', ['loggedUser']),

  },
};
</script>
