<template>
  <div>
    <f7-navbar
      class="navbar-presence"
      :sliding="false"
    >
      <f7-nav-left back-link="Back" />
      <f7-nav-title>
        {{ text }}
      </f7-nav-title>
      <f7-nav-right sliding>
        <ExtraElement
          :icon-enable="newIconEnable"
          :info="btnNew"
        />
      </f7-nav-right>
    </f7-navbar>
  </div>
</template>

<script>
import ExtraElement from '../extraElement.vue';

export default {
  components: {
    ExtraElement,
  },
  props: {
    newIconEnable: { type: Boolean, default: false },
    text: { type: String, default: '' },
  },
  data() {
    return {
      btnNew: { urlImage: 'icon-new', urlDestiny: '/nfcManually/' },
      iconEnable: { type: Boolean, default: false },
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/presence/navBarPresence.scss";
</style>
