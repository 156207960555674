<template>
  <f7-block>
    <f7-list class="no-hairlines">
      <f7-list-item
        title="Parcela "
        smart-select
        :smart-select-params="{openIn: 'popover'}"
        class="color"
      >
        <select
          name="parcela"
          @change="selectLocation"
        >
          <option
            value=""
            selected
          />
          <option
            v-for="(location, index) in locations"
            :key="index"
            :value="location.id"
            @click="selectLocation(location.id)"
          >
            {{ location.name }}
          </option>
        </select>
      </f7-list-item>
      <f7-list />
    </f7-list>
  </f7-block>
</template>

<script>
import EventBus from '../js/event-bus';

export default {
  name: 'LocationPicker',
  data() {
    return {
      locations: null,
    };
  },
  beforeMount() {
    EventBus.$on('locations', this.saveLocations);
  },
  methods: {
    selectLocation() {
      const locationSelected = this.$f7.smartSelect.get().getValue();
      EventBus.$emit('locationSelected', locationSelected);
      this.$f7.smartSelect.close();
    },
    saveLocations(locationsJson) {
      this.locations = JSON.parse(locationsJson).data;
    },

  },
};
</script>

<style lang="scss">
.list .item-inner{
  display: flex !important;
}
.color{
  color: #3F1815;
}
</style>
