<template>
  <f7-panel
    left
    cover
    :opened="showToDefault"
  >
    <div class="content-menu-header">
      <div class="menu-header-item logoSize">
        <img
          class="image_logo"
          :src="APP_LOGO"
        >
      </div>
    </div>
    <f7-list>
      <f7-list-group
        v-for="(item, index) in elements"
        :key="index"
        :link="item.url"
      >
        <f7-list-item
          v-if="item.name!=''"
          :title="item.name"
          group-title
        />
        <f7-list-item
          v-for="(itemMenu, index2) in item.menus"
          :key="index2"
          :link="itemMenu.url"
          no-chevron
          class="link panel-close close"
        >
          <f7-icon
            v-if="itemMenu.image!=''"
            :class="`icon-menu ${itemMenu.icon}`"
            :link="itemMenu.url"
          />
          <a :href="itemMenu.url">{{ itemMenu.name }}</a>
        </f7-list-item>
      </f7-list-group>
    </f7-list>
  </f7-panel>
</template>

<script>

import { APPLICATION_NAME, APP_LOGO } from '../js/constants';

export default {
  name: 'LeftPanel',
  props: {
    elements: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: false },
  },
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
  },
};
</script>

<style lang="scss">
.content-menu-header {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-direction: normal;
      -moz-box-direction: normal;
      -webkit-box-orient: horizontal;
      -moz-box-orient: horizontal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: start;
      -moz-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
      -webkit-box-align: start;
      -moz-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      padding-right: 10px;
      }

    .content-menu-header .menu-header-item:nth-child(1) {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1 1 64px;
      -ms-flex: 1 1 64px;
      flex: 1 1 64px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      }

    .content-menu-header .menu-header-item:nth-child(2) {
      -webkit-box-ordinal-group: 1;
      -moz-box-ordinal-group: 1;
      -webkit-order: 0;
      -ms-flex-order: 0;
      order: 0;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -webkit-flex: 1 1 80%;
      -ms-flex: 1 1 80%;
      flex: 1 1 80%;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      align-self: center;
      }

  @-moz-document url-prefix() {
  .content-menu-header {
      width: 100%;
      -moz-box-sizing: border-box;
      }

  }
  .content-menu .list ul {
    background: white;
  }
  .content-menu .list-group-title {
    border-bottom: 1px solid #ececec;
  }
  .content-menu .page-content {
    background: #F7F8FA;
    border-right: 1px solid var(--f7-list-border-color);
  }
  //TODO: Ver porqué se ha alinea a la derecha | Alinear nombre del menú a la izquierda
  .list .item-inner{
    display: block;
  }

  .icon-menu {
    width: 24px;
    height:24px;
    margin-right: 15px;
  }

  .icon-home {
    background-image: url('../static/img/icon_inicio.svg');
  }

  .icon-logout {
    background-image: url('../static/img/icon_desconectar.svg');
  }
  .logoSize{
    width: 100%;
  }
  .image_logo{
    width: inherit;
  }
</style>
