// Import Vue & Framework7
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';

// Importamos la librería de traducción
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';

// Styles
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.scss';

// Import App Component
import App from '../components/app.vue';
import store from '../store/index';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

// Inicializamos la librería de traducción
Vue.use(VueI18Next);

// const api = new Api();
const dictionaries = { // Simulamos la llamada a la API para obtener las traducciones
  en: {
    translation: {
      Login_txtWelcome: 'Welcome back!',
      Login_inpUsername_placeholder: 'Username',
      Login_inpPassword_label: 'Password',
      Login_inpPassword_placeholder: 'Password',
      Login_chkRememberMe: 'Remember me',
      Login_txtRestorePass: 'Restore Password',
      Login_txtNeedHelp: 'Do you need some help?',
      Login_btnSignIn: 'SIGN IN',
      Login_btnSignUp_question: 'Not registered yet?',
      Login_btnSignUp_link: 'Sign up here',
      Login_msgEmail_title: 'Information',
      Login_msgEmail_content: 'You will shortly receive an email to change your password',
      Login_msgEmail_btnClose: 'Close',
      RecoveryPassword_txtDataAccess: 'Enter your email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reset password',
      RecoveryPassword_btnGoBack: 'Go back',
      ChangePassword_txtDataAccess: 'Enter your new password',
      ChangePassword_inpEmail_placeholder: 'Password',
      ChangePassword_inpRepeatEmail_placeholder: 'Repeat password',
      ChangePassword_btnChangePassword: 'Change password',
      Register_registerText: 'REGISTER',
      Register_btnEnter: 'ENTER',
      Register_inpName_name: 'Name',
      Register_inpName_placeholder: 'Name',
      Register_inpSurnames_name: 'Surnames',
      Register_inpSurnames_placeholder: 'Surnames',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Password',
      Register_inpPassword_placeholder: 'Password',
      Register_inpPasswordVerification_name: 'RepeatPassword',
      Register_inpPasswordVerification_placeholder: 'Repeat password',
      Register_inpUsername_name: 'Username',
      Register_inpUsername_placeholder: 'Username',
      Nfc_info_txtTitle: 'Card reading',
      Nfc_info_txtSubTitle: 'Approach the card for reading',
      Nfc_navbarpresence_incidence: 'NFC scanning',
      Nfc_navbarpresence_manual: 'Add NFC manually',
      Nfc_navbarpresence_btnContinue: 'Continue',
      Profile_info_profile: 'Profile info',
      Profile_name_placeholder: 'Name',
      Profile_surname_label: '',
      Profile_surname_placeholder: '',
      Profile_email_label: '',
      Profile_email_placeholder: '',
      Profile_mobile_label: '',
      Profile_mobile_placeholder: '',
      Profile_password_profile: '',
      Profile_oldPass_label: '',
      Profile_oldPass_placeholder: '',
      Profile_newPass_label: '',
      Profile_newPass_placeholder: '',
      Profile_checkNewPass_label: '',
      Profile_checkNewPass_placeholder: '',
      Profile_change_password: '',
      Profile_photo_profile: '',
      Profile_update_photo: '',
      Profile_setup_profile: '',
      Profile_language_label: '',
      Profile_theme_label: '',
      Profile_save_changes: '',
      Home_info_txtTitle: '',
      ExportWorkerList_btnExport: '',
      ExportExcel_thNombre: '',
      ExportExcel_thTipo: '',
      ExportExcel_thFecha: '',
      ExportExcel_captionListaEmpleados: '',
      SheetExport_sheet_export_ok: 'Downloaded file: ',
      SheetExport_sheet_export_error: 'Error into download',
    },
  },
  es: {
    translation: {
      Login_txtWelcome: '¡Bienvenido de nuevo!',
      Login_inpUsername_placeholder: 'Usuario',
      Login_inpPassword_label: 'Contraseña',
      Login_inpPassword_placeholder: 'Contraseña',
      Login_chkRememberMe: 'Recordar contraseña',
      Login_txtRestorePass: 'Cambiar contraseña',
      Login_txtNeedHelp: '¿Necesitas ayuda?',
      Login_btnSignIn: 'ENTRAR',
      Login_btnSignUp_question: '¿Todavía no te has registrado?',
      Login_btnSignUp_link: 'Registrate aquí',
      Login_msgEmail_title: 'Información',
      Login_msgEmail_content: 'En breve recibirá un email para cambiar su contraseña',
      Login_msgEmail_btnClose: 'Cerrar',
      RecoveryPassword_txtDataAccess: 'Introduzca su email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reestrablecer contraseña',
      RecoveryPassword_btnGoBack: 'Volver',
      ChangePassword_txtDataAccess: 'Introduzca su nueva contraseña',
      ChangePassword_inpEmail_placeholder: 'Contraseña',
      ChangePassword_inpRepeatEmail_placeholder: 'Repita contraseña',
      ChangePassword_btnChangePassword: 'Cambiar contraseña',
      Register_btnEnter: 'ENTRAR',
      Register_inpName_name: 'Nombre',
      Register_inpName_placeholder: 'Nombre',
      Register_inpSurnames_name: 'Apellidos',
      Register_inpSurnames_placeholder: 'Apellidos',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Contrasena',
      Register_inpPassword_placeholder: 'Contraseña',
      Register_inpPasswordVerification_name: 'RepetirContrasena',
      Register_inpPasswordVerification_placeholder: 'Repetir contraseña',
      Register_inpUsername_name: 'NombreUsuario',
      Register_inpUsername_placeholder: 'Nombre de usuario',
      Nfc_info_txtTitle: 'Lectura de tarjeta',
      Nfc_info_txtSubTitle: 'Aproxime la tarjeta para su lectura',
      Nfc_navbarpresence_incidence: 'Escaneo de NFC',
      Nfc_navbarpresence_manual: 'Añadir NFC manual',
      Nfc_navbarpresence_btnContinue: 'Continuar',
      Profile_info_profile: 'Información de perfil',
      Profile_name_placeholder: 'Nombre',
      Profile_surname_label: 'Apellidos',
      Profile_surname_placeholder: 'Apellidos',
      Profile_email_label: 'E-mail',
      Profile_email_placeholder: 'E-mail',
      Profile_mobile_label: 'Teléfono',
      Profile_mobile_placeholder: 'Teléfono',
      Profile_password_profile: 'Contraseña',
      Profile_oldPass_label: 'Contraseña anterior',
      Profile_oldPass_placeholder: 'Contraseña anterior',
      Profile_newPass_label: 'Nueva contraseña',
      Profile_newPass_placeholder: 'Nueva contraseña',
      Profile_checkNewPass_label: 'Confirmar nueva contraseña',
      Profile_change_password: 'Guardar contraseña',
      Profile_photo_profile: 'Foto perfil',
      Profile_update_photo: 'Actualizar foto perfil',
      Profile_setup_profile: 'Configuración',
      Profile_language_label: 'Idioma',
      Profile_theme_label: 'Tema',
      Profile_save_changes: 'Guardar cambios',
      Home_info_txtTitle: 'No hay empleados',
      ExportWorkerList_btnExport: 'Exportar a Excel',
      ExportExcel_thNombre: 'Nombre',
      ExportExcel_thTipo: 'Tipo',
      ExportExcel_thFecha: 'Fecha',
      ExportExcel_captionListaEmpleados: 'Lista de empleados',
      SheetExport_sheet_export_ok: 'Fichero descargado: ',
      SheetExport_sheet_export_error: 'Error en la descarga',

    },
  },
};
i18next.init({
  lng: 'es',
  fallbackLng: 'en',
  whitelist: ['en', 'es'],
  resources: dictionaries,
});
const i18n = new VueI18Next(i18next);


// Init App
// eslint-disable-next-line no-new
new Vue({
  el: '#app',

  // Register App Component
  components: {
    app: App,
  },
  render: (h) => h(App),
  i18n,
  store,
});
