import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import worker from './modules/worker';
import AccessControl from './modules/AccessControl';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    worker,
    AccessControl,
  },
  plugins: [new VuexPersistence({
    storage: window.localStorage,
  }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
